

.page-header__detail {
    .page-header__main {
        .page-header__row {
            .page-header__title {
                display: none;
            }
        }
    }
}

.title-he-thong {
    font-size: 20px;
    color: @primary-color;
    margin: 0px 0px 0px 20px;
    font-weight: bold;
}