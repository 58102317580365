
body {
    background-color: #edf2f7;
}

// .ant-menu{
//     color: #fff !important;
//     border-right: none !important;
// }
// .ant-menu-light{
//     background: #193550 !important;
// }
// .ant-menu-inline{
//     background: #193550 !important;
// }

// // .ant-menu-item{
// //     color: #fff !important;

// // }
// .sidebar-nav__item-link{
//     color: #fff !important;
// }

// // .ant-menu-item-selected{
// //     color: #fff !important;
// // }
// // .ng-star-inserted{
// //     color: #fff !important;
// // }


//reuse-tab
.alain-default__fixed .reuse-tab {
    display: none;
    bottom: 0;
    top: auto;
    left: auto;
    padding: 0;
}

.reuse-tab__line .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
}

@media screen and (max-width: 767px) {

    .alain-default__fixed .reuse-tab {
        position: fixed;
    }
}

//Sidebar
.alain-default__aside {
    margin-top: 54px;
    width: 250px;
}

ul.sidebar-nav li a {
    border-top: 1px solid #b0b0b0;
    border-right: 1px solid #b0b0b0;
}

ul.sidebar-nav ul.sidebar-nav__sub li a {
    border-top: none;
}

.alain-default__collapsed .rps-createOrEdit {
    width: 100% !important;
    margin-left: 0 !important;
}

.rps-createOrEdit {
    width: calc(100% - 170px) !important;
    margin-left: 170px !important;
}

nz-table {
    width: 100% !important
}

.search {
    &__form {
        &.ant-form-vertical {
            .ant-form-item {
                margin-bottom: 16px;
                margin-right: 0;
                display: block;
            }

            .ant-form-item-label,
            .ant-form-item-control-wrapper {
                padding: 0 !important;
            }
        }

        margin-bottom: 0px !important;
    }
}

.text-disabled-color {
    color: rgba(0, 0, 0, 0.25);
}

.ant-alert {
    padding-right: 30px !important;
}

.modal-include-tabs .ant-alert {
    margin-bottom: 16px !important;
}

.ant-form-item-children-icon {
    margin-right: 5px;
}

.alain-default__content nz-input-group {
    width: 100%;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-35 {
    margin-top: 35px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.margin-bottom-35 {
    margin-bottom: 35px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}
//fiz nz Icon
.anticon svg {
    display: block;
}
//btn Custom
.btn-outline-success {
    color: #0CBA70;
    border-color: #0CBA70;
}

.no-select-text {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.page-header__title, .page-header__action {
    margin-bottom: 5px;
}

.pageTitle {
    font-weight: bold;
    line-height: 45px;
    padding-left: 10px;
    font-size: 18px;
}

.ora-form-case {
    margin: 10px 0px 10px 0px !important;
    text-align: center;
}

.btn-form-case {
    margin: 0px 5px 0px 5px !important
}

.padding-0 {
    padding: 0;
    background: white !important;
}

.radion-custom {
    padding: 1em;
}

label {
    margin-top: 2px;
    margin-bottom: 2px;
}
//ant-table
.ant-table-thead > tr > th {
    color: #000;
    background: #e5eaee;
}
//ant-card
.ora-custom {
    margin-bottom: 8px !important;

    .ant-card-body {
        padding: 8px !important;
    }
}

.ora-custom-card {
    margin-bottom: 0px !important;

    .ant-card-body {
        padding: 0 !important;
    }
}

.green {
    color: green !important;
}

.red {
    color: red !important;
}

.right {
    right: 0 !important;
    float: right !important;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-left: 100px !important;
}

.qr-img {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 15vh;
}

.mb-215 {
    margin-top: -215px;
}

.ora-label-custom {
    padding-left: 15px !important;
    padding-right: 6px !important;
    padding-top: 3.5px !important;
    padding-bottom: 3.5px !important;
    border: 1px solid #d3d3d3 !important;
    font-weight: 500;
    cursor: default;
}

.ora-label-custom .icon-c {
    padding-right: 6px !important;
}

.inp-nameUsing {
    display: flex;
    width: 100% !important;
}

.inp-nameUsing input#name {
    width: 90% !important;
}

.inp-nameUsing .icon-form {
    color: #089759;
    font-size: 20px;
    margin-left: 5px !important;
    width: 10% !important;
}

@media screen and (max-width: 1028px) {
    .qr-img {
        margin-top: 20px;
        margin-left: 20px;
    }
}

@media screen and (max-width: 768px) {
    .qr-img {
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-left: 20px;
    }

    .rps-createOrEdit {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .inp-nameUsing {
        display: flex;
        width: 100% !important;
    }

    .inp-nameUsing input#name {
        width: 85% !important;
    }

    .inp-nameUsing .icon-form {
        color: #089759;
        font-size: 18px;
        margin-left: 5px !important;
        width: 10% !important;
    }
}

@media screen and (max-width: 578px) {
    .mar-left {
        margin-left: 0 !important;
    }

    .ml-10 {
        display: none !important;
    }

    .inp-nameUsing {
        display: flex;
        width: 100% !important;
    }

    .inp-nameUsing input#name {
        width: 85% !important;
    }

    .inp-nameUsing .icon-form {
        color: #089759;
        font-size: 15px;
        margin-left: 5px !important;
        width: 10% !important;
    }
}

@media screen and (max-width: 380px) {
    .ml-140 {
        margin-left: 25px !important;
    }

    .qr-img {
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-left: 0;
    }
}

.hidden {
    display: none !important;
}

//ant-notification
.ant-notification-notice-message {
    color: #fff;
}

//ant-input
.ant-input[disabled] {
    background-color: #fff;
    cursor: not-allowed;
    opacity: 1;
    color: #000;
    pointer-events: none;
    border: none;
}

.ant-input[readonly] {
    border: none;
}

.ant-input:focus, .ant-input-focused {
    box-shadow: none;
}

//ant-select
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #000;
    background: #fff;
    cursor: not-allowed;
    border: none;
}

.ant-select-disabled .ant-select-arrow {
    cursor: not-allowed;
    display: none;
}
//ant-picker
.ant-picker.ant-picker-disabled {
    background: #fff;
    border: none;
    color: #000;
}

.main-ora-date .ant-picker.ant-picker-disabled {
    border: none;
}

.ora-input-date-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
}

.oda-select-dropdown hr {
    margin: 5px;
}

.ant-form-item-explain,
.ant-form-item-extra {
    margin-top: 0px !important;
}

//modal

.modal-header {
    background: #035f96;
}

.ant-menu-inline{
 width: auto; 
}







.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-inline-collapsed > .ant-menu-item .anticon, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon{
   
    font-size: 18px;
    line-height: 40px;
    margin-left: 10px;
    margin-top: 10px;
}


.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    padding: 8px 8px;
    z-index: 1 !important;
    overflow-wrap: break-word;
}


  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color: #FFF !important;
    background-color: #0084f3;
  }
 

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 20px !important;
    margin: 0 !important;
    border-right: 1px solid #b0b0b0;
    padding-right: 0;
    padding-left: 0;
}
.ant-menu-submenu-title .anticon + span {
    // color: #fff;
    font-weight: 500;
    color: #1A202C ;
    font-size: 14px !important;
  }
  .ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    min-width: 14px;
    margin-right: 10px;
    font-size: 20px !important;
    margin-top: 9px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;                                     
  }
  .cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    z-index: 1000;

}
.ant-menu-submenu-title:hover {
    color: #FFF !important;
  background-color: none;
  span{
    color: #FFF;
  }
  i {
    color: #FFF;
  }
  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    background-color: #FFF;
  }
}

.ant-menu-submenu > .ant-menu {
    background-color: #FFF!important;
    // border-radius: 4px;
  }

  .ant-menu-vertical.ant-menu-sub .ant-menu-item, .ant-menu-vertical-left.ant-menu-sub .ant-menu-item, .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
     

  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #397fae;
    color: #089759 !important;
}
// .ant-dropdown {
//     top: 0;
//     left: 0;
//     position: relative;
//     width: 50% !important;
//     margin-top: 6px;
//     display: flex;
//     margin-bottom: 6px;
// }
//   .ant-menu {
//   background-color: #035f96;
//   }
.ant-menu-vertical.ant-menu-sub, .ant-menu-vertical-left.ant-menu-sub, .ant-menu-vertical-right.ant-menu-sub {
    min-width: 200px;
    max-height: calc(100vh - 100px);
    padding: 0;
    overflow: hidden;
    border-right: 0;
    margin-left: -15px;
    padding-left: 5px;
    padding-right: 5px;
}
.ant-menu-vertical.ant-menu-sub, .ant-menu-vertical-left.ant-menu-sub, .ant-menu-vertical-right.ant-menu-sub :last-child {
  padding-bottom: 5px ; 
}


.alain-default__fixed .reuse-tab + router-outlet {
    display: none;
    height: 52px;
}
.alain-default__fixed .alain-default__content {
    margin-top: 110px; 
}
@media (max-width: 990px ) {
    .alain-default__fixed .reuse-tab + router-outlet {
        display: block;
        height: 22px;   
    }
    .alain-default__fixed .alain-default__content {
        margin-top: 64px; 
    }
}

.ant-menu-inline .ant-menu-item-selected::after {
    display: none;
}

.root{
    padding-bottom: 8px !important; 
}
.deletMulti {
    margin-right: 5px;
}
.title-excel {
    font-weight: bold;
    line-height: 45px;
    padding-left: 10px;
    font-size: 18px;
}
.font-weight-600{
    font-weight: 600;
}