// region: PLACEHOLDER, IMPORTANT: don't remove
@import '~@delon/theme/system/index';
@import '~@delon/abc/index';
@import '~@delon/theme/layout-default/style/index';
@import '~@delon/theme/layout-blank/style/index';
@import './styles/font';
@import './styles/theme';
@import './styles/index';
@import '@toast-ui/calendar/dist/toastui-calendar.min.css';
@import 'tui-date-picker/dist/tui-date-picker.css';
@import 'tui-time-picker/dist/tui-time-picker.css';
@import './styles/responsive.less';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-filemanager/styles/material.css";
